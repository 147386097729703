.modal_wrapper {
    background: #ffffff;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    min-width: 500px;
    z-index: 1000;
    // box-shadow: -8px 0px 17px 17px #cb585814;
    border-radius: 4px;
    transition: 0.2s all linear;
    padding: 2rem;
    margin: auto;
    visibility: hidden;

    .loading {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #ffffffcf;
        font-size: 2rem;
        display: flex;
        z-index: 1;
        justify-content: center;
        padding-top: 22rem;
    }

    &.open {
        visibility: visible;
        transform: translate(-50%, -50%) scale(1);
        transition: 0.2s all linear;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
            margin: 0;
        }
    }
    .body {
        padding-top: 1rem;
    }
    .close {
        width: 28px;
        height: 28px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
            background: #80808029;
        }
        // svg {
        //     margin-left: 0.4rem;
        // }
    }
}
.modal_back_drop {
    background-color: #00000057;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    left: 0;
    top: 0;
}
