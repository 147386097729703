.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1rem;

    .left {
        h3 {
            margin: 0;
        }
    }
}
