.wrapper {
    display: flex;
    height: 100%;
    .left_bar {
        display: flex;
    }
    .right_bar {
        flex: 1;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;
    }
    .right_bar_content {
        display: flex;
        flex-flow: column wrap;
    }
    .data_bar_content {
        display: flex;
        flex-direction: column;
        padding: 1.5rem;
    }
}
