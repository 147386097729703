.wrapper {
  padding: 1rem 2rem;
  box-shadow: 0 3px 6px #00000029;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 20;
  .left {
    background: #f6f6f6;
    border-radius: 4px;
    display: inline-block;
    padding: 0.2rem 0.4rem;
    cursor: pointer;
    transition: 0.1s background-color;

    &:hover {
      background-color: #e5e0e0b5;
    }
  }
}
