.input_wrappe {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.8rem;
  flex: 1;

  &:last-child {
    margin-right: 0;
  }

  label {
    font-size: small;
    margin-bottom: 0.4rem;
    font-weight: 800;
  }
  input {
    background: transparent;
    padding: 0.6rem;
    border: 1px solid gray;
    border-radius: 4px;

    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
}
