.modal_background {
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200);
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close {
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 4px 6px;
  border-radius: 4px;
  cursor: pointer;
  float: right;
  position: absolute;
  right: 0px;
  top: 0px;
}
.close svg {
  margin-left: 0.4rem;
}

.modal_container {
  width: 80vw;
  height: 500px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: auto;
  padding: 15px;
  position: relative;
}

.hide_modal_btn {
  display: flex;
  justify-content: center;
}

.email_text {
  text-decoration: underline;
  color: #00f;
  cursor: pointer;
}
