.select_wrapper {
    position: relative;
    flex: 1;
    // margin-right: 1rem;
    min-width: 10rem;

    &:last-child {
        margin-right: 0;
    }
    &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }

    label {
        font-size: small;
        margin-bottom: 0.4rem !important;
        font-weight: 800;
        display: block;
    }
    .label_area {
        max-height: 100px;
        overflow: auto;
        background: transparent;
        padding: 0.6rem;
        border: 1px solid gray;
        border-radius: 4px;
        font-size: 13px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &.disabled {
            opacity: 0.6;
            cursor: not-allowed !important;
            // pointer-events: none;
        }
    }
    .backdrop {
        height: 100vh;
        width: 100vw;
        background: transparent;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 0;
    }
    .options_wrapper {
        border: 1px solid #bbbbbb;
        border-radius: 4px;
        position: absolute;
        left: 0;
        top: 106%;
        background: white;
        overflow: hidden;
        width: 100%;
        box-shadow: 1px 1px 6px 0px #8080808f;
        z-index: 9;
        max-height: 10rem;
        overflow: auto;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                & > section {
                    position: absolute;
                    left: 0;
                    padding-left: 6px;
                }
                position: relative;
                cursor: pointer;
                padding: 0.4rem 0.8rem;
                border-bottom: 1px solid #8080803b;
                padding-left: 1.4rem;
                font-size: 13px;
                background: white;
                &.active {
                    background-color: #ff0606;
                    color: white;
                }
                &:last-child {
                    border: 0;
                }
                &:not(.active) {
                    &:hover {
                        background-color: #ebbebe;
                    }
                }
            }
        }
    }
}

.clip_wrapper {
    padding: 0;
    list-style: none;
    margin: 0;
    display: inline-flex;
    flex-direction: row;

    flex-wrap: wrap;
    align-items: flex-start;

    li {
        border-radius: 12px;
        padding: 2px 6px;
        font-size: 10px;
        background: #ff4b3f;
        color: white;
        margin-right: 5px;
        &:last-child {
            margin-right: 0;
        }
        svg {
            margin-left: 4px;
        }
    }
}
.multi {
    // min-height: 4rem;
    align-items: flex-start !important;
}

.loading_spinner {
    margin-top: 4px;
    animation: SpinnerAnimation 1.2s ease-in-out infinite;
}
.chevron {
    margin-top: 4px;
}

@keyframes SpinnerAnimation {
    0% {
        rotate: 0deg;
    }
    100% {
        rotate: 360deg;
    }
}
