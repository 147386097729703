:root {
    --side-nav-width: 18rem;
}

.wrapper {
    background: black;
    width: var(--side-nav-width);
    color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    transition: 0.4s width;

    .logo {
        text-align: center;
        padding: 1.372rem 0.5rem;

        img {
            width: 80%;
        }
    }

    .details {
        text-align: center;
        padding: 2rem 0.5rem 1rem;
        img {
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            background: white;
        }
        p {
            margin: 0.6rem !important;
            &:nth-child(3) {
                font-size: 0.8rem;
            }
        }
    }

    ul {
        list-style: none;
        padding: 0;
        width: 100%;
        li {
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 0.8rem;
            padding: 0.6rem 0.8rem;
            margin: 0.6rem 1rem;
            border-radius: 9px;
            transition: 0.2s background-color;

            div {
                display: flex;
                align-items: center;
                width: 100%;

                text {
                    flex: 1;
                }

                img {
                    width: 1rem;
                    margin-right: 12px;
                }

                .expandArrow {
                    padding: 0.4rem;
                    width: 0.8rem;
                    height: 0.8rem;
                    border-radius: 50%;
                    &:hover {
                        background: rgba(255, 255, 255, 0.197);
                    }
                }
            }

            &.nested {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                &.open {
                    background: rgba(255, 255, 255, 0.115);
                }
            }

            &.active {
                background: #fffafa66;
            }

            &.text_labels {
                cursor: initial;
                flex-direction: column;
                align-items: flex-start;
                text {
                    &:nth-child(1) {
                        display: block;
                        color: red;
                        font-weight: 800;
                        text-transform: uppercase;
                        margin-bottom: 4px;
                    }
                }
            }

            &:not(.text_labels):not(.active):not(.nested) {
                &:hover {
                    background: rgba(255, 255, 255, 0.115);
                }
            }

            &:first-child {
                background: transparent;
            }
        }
    }
}
