@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
body {
    background-color: #ffff;
    margin: 0;
    font-family: "Nunito Sans", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

html,
body,
#root {
    height: 100%;
}
.userSelect {
    width: 13rem;
    display: inline-block;
}

.custom-tooltip {
    width: auto;
    line-height: 18px;
    overflow: hidden;
    white-space: nowrap;
    padding: 3px 10px;
    background-color: black;
    color: #ffff;
}
