/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.ag_grid_table_wrapper {
    height: 70vh;
}
.ag_grid_table_wrapper_low {
    height: 50vh;
}

.ag_grid_table_wrapper.summary_print_order .ag-row-last,
.ag_grid_table_wrapper_low.summary_print_order .ag-row-last {
    font-weight: 600;
}

/* Custom styles for SimpleBar scrollbar */

.simplebar-scrollbar {
    background: #ff3b2d; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the scrollbar */
}
.simplebar-scrollbar:before {
    background: #ff3b2d !important;
}

.simplebar-scrollbar.simplebar-visible {
    opacity: 1; /* Show scrollbar when visible */
}

.simplebar-scrollbar.simplebar-hidden {
    opacity: 0; /* Hide scrollbar when not in use */
}
