.wrapper {
    // padding: 2rem;
    // padding-top: 1rem;
    background: white;
    border-radius: 4px;
    flex: 1;

    .table_actions {
        display: flex;
        justify-content: space-between;
        position: sticky;
        top: 0;
        background: white;

        input {
            &::-webkit-input-placeholder {
                font-size: 10;
                font-weight: lighter;
            }
            margin: 0.8rem;
            background: #f6f6f6;
            border: none;
            border-radius: 4px;
            font-size: 14px;
            padding: 0.4rem;
            padding-left: 1.6rem;
            outline: none;
            &:disabled {
                opacity: 0.6;
                cursor: not-allowed;
            }
        }
    }

    .table {
        width: 100%;
        border-collapse: collapse;
        transition: all 2.4s;

        // border-bottom: 2px solid #000;
        // min-height: 20rem;
        thead {
            background-color: white;
            position: sticky;
            top: 3.4rem;
            box-shadow: 0px 11px 17px 2px #c6c6c61c;

            .th {
                // font-family: Syne, Helvetica Neue, Arial, Noto Sans, sans-serif;

                padding: 0.8rem;
                position: relative;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 0.8rem;
                letter-spacing: 1px;
                border-radius: 4px;

                // &:hover {
                //   background: #eeedec;
                // }
                // &:not(:last-child) {
                //   &::after {
                //     content: "";
                //     width: 0.5px;
                //     height: 20px;
                //     position: absolute;
                //     right: 0;
                //     top: 50%;
                //     background: #cccccc;
                //     transform: translateY(-50%);
                //   }
                // }

                .th_content_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .sort_icons_wrapper {
                        min-width: 14px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: #f6f6f6;
                        color: #3b3b3bd6;
                        border-radius: 4px;
                        transition: background-color 0.4s;
                        cursor: pointer;
                        padding: 0.4rem 0.2rem;

                        &:hover {
                            background-color: #d8d8d847;
                        }
                    }
                }
            }
        }
        tbody {
            tr {
                border-bottom: 1px solid #eeeeee;
                transition: 0.1s background-color;
                background-color: white;

                &:hover {
                    background-color: #fdf8f0;
                }

                &:last-child {
                    border: none;
                }
                .td {
                    padding: 0.8rem;
                    font-size: 0.8rem;
                    // border-right: 0.5px solid #e1e1e1;
                    &:last-child {
                        border: 0;
                    }
                }
            }
        }
        .hover_row {
            box-shadow: 0px -3px 1px grey;
        }
    }
}
