.modal_wrapper {
    background: #ffffff;
    position: fixed;
    transform: translateX(100%);
    right: 0;
    top: 0;
    height: 100%;
    min-width: 30%;
    // border-left: 2px solid red;
    z-index: 1000;
    box-shadow: -8px 0px 17px 17px #cb585814;
    transition: 0.2s all cubic-bezier(0.55, 0.06, 0.68, 0.19);
    padding: 2rem;
    // padding-top: 5rem;
    box-sizing: border-box;
    overflow: auto;

    .loading {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #ffffffcf;
        font-size: 2rem;
        display: flex;
        z-index: 1;
        justify-content: center;
        padding-top: 22rem;
    }

    &.open {
        transform: translateX(0);
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
            margin: 0;
        }
    }
    .body {
        padding-top: 2rem;
    }
    .close {
        width: 28px;
        height: 28px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
            background: #80808029;
        }
        // svg {
        //     margin-left: 0.4rem;
        // }
    }
}
.modal_back_drop {
    background-color: #00000057;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    left: 0;
    top: 0;
}
