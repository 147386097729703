.update-po-input-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    label {
        font-size: small;
        margin-bottom: 0.4rem;
        font-weight: 800;
    }
    .update-po-input {
        display: flex;
        align-items: center;
        column-gap: 10px;

        input {
            background: transparent;
            padding: 0.6rem;
            border: 1px solid gray;
            border-radius: 4px;
            width: 100px;

            &:disabled {
                opacity: 0.6;
                cursor: not-allowed;
            }
        }
    }
}
