.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    .left {
        h2 {
            margin: 0 0 0.4rem;
        }
        .breadcrumbs {
            color: #8d8d8d;
            font-size: 14px;
            strong {
                color: #373435;
            }
        }
    }
}
