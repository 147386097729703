.select {
    margin-bottom: 0;
    & > div {
        line-height: 0;
        &:first-child {
            border: 0 !important;
            background-color: #ff000026 !important;
        }
    }
}

.tableHeader {
    font-size: 0.6rem !important;
}
.tableCell {
    font-size: 0.6rem !important;
}

.caps {
    text-transform: uppercase;
}

.editIcon {
    cursor: pointer;
    background: #d9d9d924;
    padding: 8px;
    border-radius: 50%;
    color: #5b5852c7;
    transition: 0.2s all;
    &:hover {
        background: #d9d9d961;
        box-shadow: 1px 1px 5px #80808066;
    }
}

.status {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-radius: 4px;
    padding: 2px 3px;
    font-weight: 600;
}
// .pending {
//     border-color: orange;
//     color: orange;
// }
.verify {
    border-color: orange;
    color: orange;
}
.success {
    border-color: green;
    color: green;
}
.error {
    border-color: red;
    color: red;
}

.table_section {
    margin-right: 4px;
    &::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.tooltip {
    background: #f6f6f7 0% 0% no-repeat padding-box;
    padding: 0.5rem 0.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &.active {
        &::after {
            content: "";
            position: absolute;
            right: -1px;
            top: -1px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #ff4b3f;
        }
    }

    &:hover {
        background-color: #80808047;
    }
}

.filter_section {
    position: absolute;
    padding: 1rem;
    padding-bottom: 0.4rem;
    background: white;
    box-shadow: 1px -2px 4px 3px #8080800f;
    border-radius: 4px;
    z-index: 12;
    margin-top: 7px;
    width: 22rem;

    div {
        margin-bottom: 10px;
        margin-right: 0;
    }

    .actions {
        display: flex;
        justify-content: flex-end;

        button {
            font-size: 8px;
            &:first-child {
                background-color: #f6f6f7;
                color: #ff4b3f;
                margin-right: 4px;
            }
        }
    }
}
