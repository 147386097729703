button {
  display: block;
  background-color: #ff1303d4;
  border: 0;
  outline: none;
  border-radius: 4px;
  padding: 0.704rem 1.4rem;
  color: white;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 800;
  cursor: pointer;
  transition: background-color 0.2s;

  &.default {
    background-color: grey;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &:hover {
    background-color: #ff1303;
  }
}