.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .header {
    margin-top: -6rem;
    img {
      width: 12rem;
    }
  }
  .cardWrapper {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    margin-top: 0.4rem;
    width: 28%;

    .card_warpper_header {
      text-align: center;
      color: #3b404b;

      h3,
      p {
        margin: 0;
        margin: 0.4rem;
      }
      h3 {
        text-transform: uppercase;
      }
      p {
        font-weight: 400;
        margin-bottom: 1.4rem;
        font-size: 14px;
      }
    }

    .submit_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2rem;

      .forgot_password_wrapper {
        font-size: 12px;

        div {
          display: flex;
          align-items: center;
          margin-top: 0.4rem;
          cursor: pointer !important;

          input[type="checkbox"] {
            margin: 0;
            margin-right: 0.4rem;
          }
        }
      }
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 2rem;
    background: #0e0e0e;
    color: #ffffffa1;
    font-size: 14px;
    align-items: center;
    padding: 1rem;
    display: flex;
    .left {
      width: 30%;
    }
    .right {
      text-align: right;
      width: 65%;
      margin-right: 5%;
    }
    .right a {
      color: #ffffffa1;
      font-size: 14px;
      margin-left: 10px;
    }
  }
}
