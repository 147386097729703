.table_filter_wrapper {
    border: 1px solid #ccc;
    margin: 0 0 1rem;
    padding: 1rem;
    border-radius: 4px;
}
.table_filter {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 1rem;
}
.table_filter_columns_3 {
    grid-template-columns: 1fr 1fr 1fr;
}
.table_filter_columns_4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

/* filter field */
.filter_field_label {
    display: block;
    font-size: small;
    margin-bottom: 0.4rem;
    font-weight: 800;
}

/* DATE */

.filter_field_date {
    display: flex;
    justify-content: space-between;
}
.filter_field_date > input[type="date"] {
    width: 100%;
    padding: 0.8rem 0.6rem;
    background: #f6f6f7;
    border-radius: 4px;
    font-size: 12px;
    border: 0;
}

.filter_field_date_range > input[type="date"]{
    width: 50%;
}
.filter_field_date_range > input[type="date"]:first-of-type {
    margin-right: 10px;
}

/* SELECT BOX */

.filter_field select,
.filter_field input {
    height: 2.5rem;
    max-width: 25vw;
    width: 100%;
    border-radius: 4px;
    background: #fff;
    font-size: 13px;
    padding: 0.6rem;
}

/* filter button */

.table_filter_button {
    width: 100%;
    display: flex;
    margin-top: 1rem;
}

